<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section section-gap-30">
                <SettingMenu></SettingMenu>
                <div class="setting-content">
                    <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
                        <div class="section-title margin-deliver">
                            {{ $t(`Notification Settings`) }}
                        </div>
                    </div>
                    <div class="data-table user-table" v-if="isContentLoading" style="background: #fff; border-radius: 10px; padding: 30px;">
                        <NotificationLoader></NotificationLoader>
                    </div>
                    <div class="data-table user-table" v-else>
                        <h4 class="empty-message" v-show="isEmpty">{{ $t(`No notification setting Found`) }}</h4>
                        <div class="section__block">
                            <div class="ej-table notification__table" v-show="!isEmpty">
                                <div class="table__row table__head">
                                    <div class="table__col col__content">
                                        <h5>Title</h5>
                                    </div>
                                    <div class="table__col col__switch">
                                        <span>{{ $t(`Push`) }}</span>
                                    </div>
                                    <div class="table__col col__switch">
                                        <span>{{ $t(`Email`) }}</span>
                                    </div>
                                </div>
                                <div class="table__row" v-for="(notification, index) in notifications">
                                    <div class="table__col col__content">
                                        <div class="item__content">
                                            <h5 class="mb-1"><i class="eicon" :class="notification.icon"></i>{{ $t(notification.title) }}</h5>
                                            <p >{{ $t(notification.hint) }}</p>
                                        </div>
                                    </div>
                                    <div class="table__col col__switch">
                                        <div class="item__switch">
                                            <label class="ej-switch">
                                                <input type="checkbox"  @change="saveUserNotification" v-model="notifications[index].push">
                                                <span class="switch__btn">
                                                <span class="on">On</span>
                                                <span class="off">Off</span>
                                            </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="table__col col__switch">
                                        <div class="item__switch">
                                            <label class="ej-switch">
                                                <input type="checkbox" @change="saveUserNotification" v-model="notifications[index].email">
                                                <span class="switch__btn">
                                                <span class="on">On</span>
                                                <span class="off">Off</span>
                                            </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>
const DashboardLayout = () => import("../../../layouts/DashboardLayout");
const SettingMenu = () => import("../../../components/company/SettingSidebar");
const NotificationLoader = () => import("../../../components/_loaders/_NotificationLoader");

import client from "../../../app/api/Client";
import {mapActions, mapState} from 'vuex';
import {SWITCH_TO_ACCOUNT} from "../../../constants/action-type";

export default {
    components: {
        DashboardLayout,
        SettingMenu,
        NotificationLoader
    },

    data() {
        return {
            notifications: [],
            notificationPush: false,
            notificationEmail: false,
            isContentLoading:false
        }
    },

    computed: {
        ...mapState(['user', 'company']),
        isEmpty() {
            return this.notifications.length === 0;
        },
    },
    methods: {
        ...mapActions([SWITCH_TO_ACCOUNT]),
        async getUserNotification() {
            this.isContentLoading = true;
            try {
                let {data: {data}} = await client().get('/company/setting/user/notifications');
                this.notifications = data;
            } catch (e) {
            }
            this.isContentLoading = false;
        },

        async saveUserNotification() {
            try {
                let {data: {data, message}} = await client().post('/company/setting/user/notifications', this.notifications);
                this.notifications = data;
                this.$toast.success(message);
            } catch (e) {
                this.$toast.error(e.response.data.message);/* System error */
            } finally {
                this.allSelected();
            }
            this.isContentLoading = false;
        },
        toggleOnOffNotification(type) {
            _.map(this.notifications, (notification, index, arr)=> {
                if(type === 'email') {
                    arr[index]['email'] = this.notificationEmail;
                }
                if(type === 'push') {
                    arr[index]['push'] = this.notificationPush;
                }
                return arr;
            });

            this.saveUserNotification();
        },
        allSelected() {
            let allPush = true;
            let allEmail = true;
            _.forEach(this.notifications, (notification, index)=> {
              if(allEmail && !notification.email)  {
                  allEmail = false;
              }
                if(allPush && !notification.push)  {
                    allPush = false;
                }
            });

            this.notificationEmail = allEmail;
            this.notificationPush = allPush;

        }
    },
    async mounted() {
        await this.getUserNotification();
        this.allSelected();
    }
}
</script>

<style scoped>
.table-wrap .table .table__row .table-cell .d-flex >span {
    margin-right: 10px;
}

@media all and (max-width: 1600px) {
    .ej-switch .switch__btn {
        height: 27px;
        width: 58px;
    }

    .ej-switch .switch__btn::before {
        height: 17px;
        width: 17px;
    }

    .ej-switch input:checked ~ .switch__btn::before {
        left: 35px;
    }
}

.section__block {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
}
.notification__table {
    overflow: auto;
}
.notification__table .table__row {
    display: flex;
    align-item: center;
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    min-width: 620px;
}
.notification__table .table__row.table__head {
    padding-bottom: 10px;
}
.notification__table .table__row:first-child {
    padding-top: 0;
}
.notification__table .table__row:last-child {
    padding-bottom: 0;
    border-bottom: 0;
}
.notification__table .table__row .table__col {
    padding: 5px;
    background: #fff;
}

/*.notification__table .table__row.table__head .table__col {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    gap: 8px;*/
/*}*/
.notification__table .table__row.table__head .table__col.col__content h5 {
    font-size: 18px;
    font-weight: 500;
}
.notification__table .table__row .table__col.col__content {
    flex: 1;
}
.notification__table .table__row .table__col.col__content h5 {
    font-size: 15px;
    font-weight: 500;
}
.notification__table .table__row .table__col.col__content h5 i {
    font-size: 16px;
    margin-right: 8px;
}
.notification__table .table__row .table__col.col__content p {
    font-size: 14px;
    color: #7d8091;
}
.notification__table .table__row .table__col.col__switch {
    flex: 0 0 160px;
}
@media all and (max-width: 767px) {
    .section__block {
        padding: 15px;
    }
    .notification__table .table__row .table__col.col__switch {
        flex: 0 0 100px;
    }
}
</style>
